const colors = {
    richBlack: {
        50: "#A0B3BF",
        100: "#8C9DA4",
        200: "#68808C",
        300: "#030E20",
        400: "#030E20",
        500: "#030E20",
        600: "#030E20",
        700: "#030E20",
        800: "#030E20",
        900: "#030E20",
    },
    slateGray: {
        50: "#B7C5C7",
        100: "#A1B2B4",
        200: "#68808C",
        300: "#68808C",
        400: "#68808C",
        500: "#68808C",
        600: "#68808C",
        700: "#68808C",
        800: "#68808C",
        900: "#68808C",
    },
    pacificCyan: {
        50: "#D2F3F7",
        100: "#B7E9EB",
        200: "#0DB3D4",
        300: "#0DB3D4",
        400: "#0DB3D4",
        500: "#0DB3D4",
        600: "#0DB3D4",
        700: "#0DB3D4",
        800: "#0DB3D4",
        900: "#0DB3D4",
    },
    nonPhotoBlue: {
        50: "#E4F1F3",
        100: "#B3E0E5",
        200: "#8CC7D3",
        300: "#8CC7D3",
        400: "#8CC7D3",
        500: "#8CC7D3",
        600: "#8CC7D3",
        700: "#8CC7D3",
        800: "#8CC7D3",
        900: "#8CC7D3",
    },
    azureWeb: {
        50: "#F0F4F5",
        100: "#D3E3E5",
        200: "#D3E3E5",
        300: "#D3E3E5",
        400: "#D3E3E5",
        500: "#D3E3E5",
        600: "#D3E3E5",
        700: "#D3E3E5",
        800: "#D3E3E5",
        900: "#D3E3E5",
    },
};

export default colors;
