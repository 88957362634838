import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Restaurant } from "../types/Restaurant";

export async function deleteRestaurant(restaurant: Restaurant) {
    const restaurantRef = doc(db, "restaurants", restaurant.id);
    const restaurantSnap = await getDoc(restaurantRef);

    if (restaurantSnap.exists()) {
        await deleteDoc(restaurantRef);
    } else {
        throw new Error("Failed to delete restaurant!");
    }
}
