import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Restaurant } from "../types/Restaurant";

export async function getRestaurant(
    restaurantId: string
): Promise<Restaurant | null> {
    const restaurantRef = doc(db, "restaurants", restaurantId);
    const restaurantSnap = await getDoc(restaurantRef);

    if (!restaurantSnap.exists()) {
        return null;
    }

    return {
        id: restaurantSnap.id,
        ...restaurantSnap.data(),
    } as Restaurant;
}
