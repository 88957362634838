import { Box, Link, Text, VStack } from "@chakra-ui/react";

export const Footer = () => (
    <Box
        width="100%"
        textAlign="center"
        paddingY="4"
        position="fixed"
        bottom="0"
        left="0"
        right="0"
    >
        <VStack>
            <Text>
                Made with 🫶 by{" "}
                <Link isExternal href="https://www.northsoftsolutions.com">
                    Northsoft Solutions
                </Link>
            </Text>
        </VStack>
    </Box>
);
