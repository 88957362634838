import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

export async function createRestaurant(
    userId: string,
    name: string,
    location: string,
    cuisine: string
) {
    await addDoc(collection(db, "restaurants"), {
        ownerId: userId,
        name,
        location,
        cuisine,
    });
}
