import { CreateToastFnReturn } from "@chakra-ui/react";

export function errorToast(
    toast: CreateToastFnReturn,
    title: string,
    description: string
) {
    toast({
        title,
        description,
        status: "error",
        duration: 5000,
        isClosable: true,
    });
}
