const alert = {
    variants: {
        info: {
            bg: "nonPhotoBlue.100",
            color: "richBlack.500",
            border: "1px solid",
            borderColor: "nonPhotoBlue.300",
        },
        success: {
            bg: "pacificCyan.100",
            color: "richBlack.500",
            border: "1px solid",
            borderColor: "pacificCyan.300",
        },
        warning: {
            bg: "slateGray.100",
            color: "richBlack.500",
            border: "1px solid",
            borderColor: "slateGray.300",
        },
        error: {
            bg: "red.100",
            color: "richBlack.500",
            border: "1px solid",
            borderColor: "red.300",
        },
    },
};

export default alert;
