import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Restaurant } from "../types/Restaurant";

export async function updateRestaurant(restaurant: Restaurant) {
    const restaurantRef = doc(db, "restaurants", restaurant.id);
    const restaurantSnap = await getDoc(restaurantRef);

    if (restaurantSnap.exists()) {
        await setDoc(restaurantRef, restaurant);
    } else {
        throw new Error("Failed to update restaurant!");
    }
}
