const input = {
    variants: {
        outline: {
            field: {
                borderColor: "slateGray.200",
                _focus: {
                    borderColor: "pacificCyan.500",
                    boxShadow: "0 0 0 1px pacificCyan.500",
                },
            },
        },
    },
};

export default input;
