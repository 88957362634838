const button = {
    variants: {
        solid: {
            bg: "pacificCyan.500",
            color: "white",
            _hover: {
                bg: "pacificCyan.400",
            },
            _active: {
                bg: "pacificCyan.600",
            },
        },
        outline: {
            border: "2px solid",
            borderColor: "pacificCyan.500",
            color: "pacificCyan.500",
            _hover: {
                bg: "pacificCyan.50",
            },
            _active: {
                bg: "pacificCyan.100",
            },
        },
    },
};

export default button;
