import {
    Button,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Text,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Restaurant } from "ui/src/types/Restaurant";
import backgroundImage from "../../../assets/create-restaurant-splash.jpg";
import { useAuth } from "../../../contexts/AuthContext";
import { deleteRestaurant } from "../../../db/deleteRestaurant";
import { updateRestaurant } from "../../../db/editRestaurant";
import { getRestaurant } from "../../../db/getRestaurant";
import { errorToast } from "../../../helpers/errorToast";
import { successToast } from "../../../helpers/successToast";
import { DashboardLayout } from "../../layouts/DashboardLayout";
import ConfirmDenyModal from "../../modals/ConfirmDenyModal";

export const EditRestaurantPage = () => {
    const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
    const [validationErrors, setValidationErrors] = useState({
        name: "",
        location: "",
        cuisine: "",
    });

    const { userId } = useAuth();
    const { restaurantId } = useParams();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();

    useEffect(() => {
        async function getData() {
            if (!restaurantId) return;
            const restaurant = await getRestaurant(restaurantId);
            setRestaurant(restaurant);
        }

        getData();
    }, [restaurantId, userId]);

    const validateFields = () => {
        const errors = { name: "", location: "", cuisine: "" };
        if (!restaurant?.name) errors.name = "Name is required.";
        if (!restaurant?.location) errors.location = "Location is required.";
        if (!restaurant?.cuisine) errors.cuisine = "Cuisine is required.";
        setValidationErrors(errors);

        return !errors.name && !errors.location && !errors.cuisine;
    };

    const editRestaurant = async () => {
        if (!validateFields()) return;
        try {
            if (!restaurant) return;
            await updateRestaurant(restaurant);
            successToast(toast, "Restaurant updated successfully!");
        } catch (error: any) {
            errorToast(toast, "Error updating restaurant!", error.message);
        }
    };

    const removeRestaurant = async () => {
        try {
            if (!restaurant) return;
            await deleteRestaurant(restaurant);
            successToast(toast, "Restaurant deleted successfully!");
            navigate("/dashboard");
        } catch (error: any) {
            errorToast(toast, "Error deleting restaurant!", error.message);
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!restaurant) return;
        const { name, value } = e.target;

        setRestaurant((prevRestaurant) => {
            if (!prevRestaurant) return null;

            return {
                ...prevRestaurant,
                [name]: value,
            } as Restaurant;
        });
    };

    const formFields = [
        { label: "Name", name: "name", placeholder: "Name" },
        { label: "Location", name: "location", placeholder: "Location" },
        { label: "Cuisine", name: "cuisine", placeholder: "Cuisine" },
    ];

    if (!restaurant)
        return <DashboardLayout backgroundImage={backgroundImage} />;

    return (
        <DashboardLayout backgroundImage={backgroundImage}>
            <VStack>
                <Heading mb={4}>Edit Restaurant</Heading>

                {formFields.map((field) => (
                    <FormControl key={field.name} mb={4}>
                        <FormLabel>{field.label}</FormLabel>
                        <Input
                            type="text"
                            name={field.name}
                            placeholder={field.placeholder}
                            value={
                                restaurant[field.name as keyof Restaurant] || ""
                            }
                            onChange={handleInputChange}
                        />
                        {validationErrors[
                            field.name as keyof typeof validationErrors
                        ] && (
                            <Text color="red.500">
                                {
                                    validationErrors[
                                        field.name as keyof typeof validationErrors
                                    ]
                                }
                            </Text>
                        )}
                    </FormControl>
                ))}

                <HStack>
                    <Button colorScheme="teal" onClick={editRestaurant}>
                        Save Restaurant
                    </Button>

                    <Button colorScheme="red" onClick={onOpen}>
                        Delete Restaurant
                    </Button>
                </HStack>
            </VStack>

            <ConfirmDenyModal
                title="Confirm deletion"
                description={`Are you sure you want to delete "${restaurant.name}"?`}
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={removeRestaurant}
            />
        </DashboardLayout>
    );
};
