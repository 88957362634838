const card = {
    variants: {
        default: {
            bg: "azureWeb.100",
            borderRadius: "md",
            boxShadow: "lg",
            border: "1px solid",
            borderColor: "slateGray.200",
        },
    },
};

export default card;
