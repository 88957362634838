import { Box, Flex, HStack, useColorModeValue } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { Footer } from "../sections/Footer";
import { RestaurantLister } from "../sections/RestaurantLister";
import { TopToolbar } from "../sections/TopToolbar";
import { ImageContainer } from "../ui/ImageContainer";

export const DashboardLayout = ({
    backgroundImage,
    children,
}: PropsWithChildren<{ backgroundImage: string }>) => {
    return (
        <>
            <TopToolbar />
            <ImageContainer backgroundImage={backgroundImage}>
                <HStack
                    width="100%"
                    height="calc(100vh - 64px)"
                    spacing={0}
                    zIndex={1}
                >
                    <RestaurantLister />

                    <Flex
                        flex="1"
                        justify="center"
                        align="center"
                        color="white"
                        textAlign="center"
                    >
                        <Box
                            bg={useColorModeValue("gray.100", "gray.900")}
                            borderRadius="lg"
                            p={12}
                        >
                            {children}
                        </Box>
                    </Flex>
                </HStack>
            </ImageContainer>
            <Footer />
        </>
    );
};
