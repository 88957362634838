import { Heading, Text, VStack } from "@chakra-ui/react";
import backgroundImage from "../../assets/dashboard-splash.jpg";
import { DashboardLayout } from "../layouts/DashboardLayout";

export const DashboardPage = () => {
    return (
        <DashboardLayout backgroundImage={backgroundImage}>
            <VStack spacing={6}>
                <Heading size="2xl">Welcome back!</Heading>
                <Text fontSize="lg" mt={4}>
                    Select a restaurant from the list, or create a new one to
                    get started.
                </Text>
            </VStack>
        </DashboardLayout>
    );
};
