const Box = {
    variants: {
        default: {
            bg: "azureWeb.100",
            borderRadius: "md",
            boxShadow: "md",
        },
        rounded: {
            bg: "pacificCyan.50",
            borderRadius: "full",
            boxShadow: "lg",
        },
        subtle: {
            bg: "nonPhotoBlue.50",
            border: "1px solid",
            borderColor: "nonPhotoBlue.300",
            borderRadius: "md",
        },
    },
};

export default Box;
