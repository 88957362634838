import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import { AppProvider } from "./contexts/AppProvider";
import initializeFirebase from "./firebase";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

initializeFirebase();

root.render(
    <React.StrictMode>
        <ColorModeScript />

        <AppProvider>
            <App />
        </AppProvider>
    </React.StrictMode>
);

serviceWorker.unregister();

reportWebVitals();
