import { Box } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export const ImageContainer = ({
    children,
    backgroundImage,
}: PropsWithChildren<{ backgroundImage: string }>) => (
    <Box
        height="calc(100vh - 64px)"
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${backgroundImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        _before={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 0,
        }}
    >
        {children}
    </Box>
);
