import { CreateToastFnReturn } from "@chakra-ui/react";

export function successToast(toast: CreateToastFnReturn, title: string) {
    toast({
        title,
        status: "success",
        duration: 5000,
        isClosable: true,
    });
}
