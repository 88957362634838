import {
    Button,
    Divider,
    Heading,
    Link,
    List,
    ListItem,
    Spinner,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Restaurant } from "ui/src/types/Restaurant";
import { useAuth } from "../../contexts/AuthContext";
import { getRestaurants } from "../../db/getRestaurants";

export const RestaurantLister = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);

    const { userId } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        async function getData() {
            const data = await getRestaurants(userId || "");
            setRestaurants(data);
            setIsLoading(false);
        }

        getData();
    }, [userId]);

    return (
        <VStack height="100%" m={4} pt={8} align="start" spacing={8}>
            <Heading color="white" size="md">
                Your Restaurants
            </Heading>

            {isLoading && <Spinner alignSelf="center" />}

            {!isLoading && restaurants.length === 0 ? (
                <Text>You have no restaurants!</Text>
            ) : (
                <List spacing={3}>
                    {restaurants.map((restaurant, index) => (
                        <ListItem key={restaurant.id}>
                            <Link
                                color="teal.300"
                                onClick={() =>
                                    navigate(`/restaurants/${restaurant.id}`)
                                }
                            >
                                {restaurant.name}
                            </Link>
                        </ListItem>
                    ))}
                </List>
            )}

            <Divider />

            <Button
                colorScheme="teal"
                onClick={() => navigate("/restaurants/create")}
                width="100%"
            >
                Create New Restaurant
            </Button>
        </VStack>
    );
};
