import { Image, ImageProps, forwardRef } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";

export const Logo = forwardRef<ImageProps, "img">((props, ref) => {
    return (
        <Link to="/">
            <Image boxSize="50px" src={logo} ref={ref} {...props} />
        </Link>
    );
});
