import { extendTheme } from "@chakra-ui/react";
import alert from "./alert";
import box from "./box";
import button from "./button";
import card from "./card";
import colours from "./colors";
import gradients from "./gradients";
import input from "./input";

export default extendTheme({
    config: {
        initialColorMode: "dark",
        useSystemColorMode: false,
    },
    colors: colours,
    gradients,
    components: {
        button,
        card,
        alert,
        input,
        box,
    },
});
