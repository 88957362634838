import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";

export interface ConfirmDenyModalProps {
    title: string;
    description: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmDenyModal = ({
    title,
    description,
    isOpen,
    onClose,
    onConfirm,
}: ConfirmDenyModalProps) => {
    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    const handleDeny = () => {
        onClose();
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>{description}</ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" onClick={handleDeny}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={handleConfirm}
                            ml={3}
                        >
                            Confirm
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ConfirmDenyModal;
