import { Box, Heading, Spinner, VStack } from "@chakra-ui/react";

export interface LoadingSpinnerProps {
    label?: string;
}

export const LoadingSpinner = ({ label }: LoadingSpinnerProps) => {
    return (
        <Box
            className="LoadingSpinner"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            backgroundColor="rgba(0, 0, 0, 0.8)"
            zIndex="9999"
            data-testid="loading-spinner"
        >
            {(label && (
                <VStack spacing={10}>
                    <Heading color="darkgrey">{label}</Heading>
                    <Spinner size="xl" />
                </VStack>
            )) || <Spinner size="xl" />}
        </Box>
    );
};
