import { PropsWithChildren } from "react";
import { Footer } from "../sections/Footer";
import { TopToolbar } from "../sections/TopToolbar";
import { ImageContainer } from "../ui/ImageContainer";

export const LandingLayout = ({
    backgroundImage,
    children,
}: PropsWithChildren<{ backgroundImage: string }>) => {
    return (
        <>
            <TopToolbar />
            <ImageContainer backgroundImage={backgroundImage}>
                {children}
            </ImageContainer>
            <Footer />
        </>
    );
};
