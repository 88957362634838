import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { AuthProvider } from "../contexts/AuthContext";
import theme from "../theme";

export const AppProvider = ({ children }: React.PropsWithChildren) => {
    return (
        <ChakraProvider theme={theme}>
            <AuthProvider>{children}</AuthProvider>
        </ChakraProvider>
    );
};
