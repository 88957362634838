import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom";
import { ProtectedRoutes } from "./components/common/ProtectedRoutes";
import { DashboardPage } from "./components/pages/DashboardPage";
import { LandingPage } from "./components/pages/LandingPage";
import { CreateRestaurantPage } from "./components/pages/restaurants/CreateRestaurantPage";
import { EditRestaurantPage } from "./components/pages/restaurants/EditRestaurantPage";
import { LoadingSpinner } from "./components/ui/LoadingSpinner";
import { useAuth } from "./contexts/AuthContext";

export const App = () => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={
                        isAuthenticated ? (
                            <Navigate to="/dashboard" />
                        ) : (
                            <LandingPage />
                        )
                    }
                />
                <Route
                    path="/login"
                    element={<LandingPage autoSignIn={true} />}
                />
                <Route element={<ProtectedRoutes />}>
                    <Route
                        path="/restaurants/:restaurantId"
                        element={<EditRestaurantPage />}
                    />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route
                        path="/restaurants/create"
                        element={<CreateRestaurantPage />}
                    />
                </Route>
            </Routes>
        </Router>
    );
};

export default App;
