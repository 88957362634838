const gradients = {
    top: "linear-gradient(0deg, #030E20, #68808C, #0DB3D4, #8CC7D3, #D3E3E5)",
    right: "linear-gradient(90deg, #030E20, #68808C, #0DB3D4, #8CC7D3, #D3E3E5)",
    bottom: "linear-gradient(180deg, #030E20, #68808C, #0DB3D4, #8CC7D3, #D3E3E5)",
    left: "linear-gradient(270deg, #030E20, #68808C, #0DB3D4, #8CC7D3, #D3E3E5)",
    topRight:
        "linear-gradient(45deg, #030E20, #68808C, #0DB3D4, #8CC7D3, #D3E3E5)",
    bottomRight:
        "linear-gradient(135deg, #030E20, #68808C, #0DB3D4, #8CC7D3, #D3E3E5)",
    topLeft:
        "linear-gradient(225deg, #030E20, #68808C, #0DB3D4, #8CC7D3, #D3E3E5)",
    bottomLeft:
        "linear-gradient(315deg, #030E20, #68808C, #0DB3D4, #8CC7D3, #D3E3E5)",
    radial: "radial-gradient(#030E20, #68808C, #0DB3D4, #8CC7D3, #D3E3E5)",
};

export default gradients;
