"use client";

import {
    Avatar,
    Box,
    Button,
    Center,
    Flex,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Stack,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import { useAuth } from "../../contexts/AuthContext";
import { SignInModal } from "../modals/SignInModal";
import { Logo } from "../ui/Logo";

export const TopToolbar = () => {
    const { isAuthenticated, auth, user } = useAuth();

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Box
                pos="sticky"
                top="0"
                zIndex="10"
                bg={useColorModeValue("gray.100", "gray.900")}
                px={4}
            >
                <Flex
                    h={16}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Logo pointerEvents="none" align={"left"} />

                    <Flex alignItems={"center"}>
                        <Stack direction={"row"} spacing={7}>
                            <Menu>
                                {isAuthenticated ? (
                                    <>
                                        <MenuButton
                                            as={Button}
                                            rounded={"full"}
                                            variant={"link"}
                                            cursor={"pointer"}
                                            minW={0}
                                        >
                                            <Avatar
                                                size={"sm"}
                                                src={
                                                    user?.photoURL ??
                                                    "https://avatar.iran.liara.run/public"
                                                }
                                            />
                                        </MenuButton>

                                        <MenuList alignItems={"center"}>
                                            <br />
                                            <Center>
                                                <Avatar
                                                    size={"2xl"}
                                                    src={
                                                        user?.photoURL ??
                                                        "https://avatar.iran.liara.run/public"
                                                    }
                                                />
                                            </Center>
                                            <br />
                                            <Center>
                                                <p>{user?.displayName}</p>
                                            </Center>
                                            <br />
                                            <MenuDivider />
                                            <MenuItem>
                                                Account Settings
                                            </MenuItem>

                                            <MenuItem
                                                onClick={() => signOut(auth)}
                                            >
                                                Sign out
                                            </MenuItem>
                                        </MenuList>
                                    </>
                                ) : (
                                    <Button onClick={onOpen}>Sign in</Button>
                                )}
                            </Menu>
                        </Stack>
                    </Flex>
                </Flex>
            </Box>

            <SignInModal isOpen={isOpen} onClose={onClose} />
        </>
    );
};
