import {
    Button,
    Divider,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Stack,
    useToast,
    VStack,
} from "@chakra-ui/react";
import {
    Auth,
    AuthProvider,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
} from "firebase/auth";
import { useState } from "react";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { errorToast } from "../../helpers/errorToast";

export interface SignInModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const SignInModal = ({ isOpen, onClose }: SignInModalProps) => {
    const [isSigningInGoogle, setIsSigningInGoogle] = useState(false);
    const [isSigningInFacebook, setIsSigningInFacebook] = useState(false);

    const navigate = useNavigate();
    const { setUser } = useAuth();
    const toast = useToast();

    const socialSignIn = async (auth: Auth, provider: AuthProvider) => {
        try {
            try {
                const result = await signInWithPopup(auth, provider);

                navigate("/dashboard");
                setUser(result.user);
            } catch (e: any) {
                errorToast(
                    toast,
                    "Error logging in!",
                    "Something bad happened, try again later?"
                );
            }
        } finally {
            onClose();
            return setIsSigningInGoogle(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    <VStack spacing={0} alignItems="center">
                        <Heading size="lg" mt={0} pt={0}>
                            Sign In
                        </Heading>
                    </VStack>
                    <Divider my={6} />
                    <Stack spacing={4} mb={4}>
                        <Button
                            leftIcon={
                                isSigningInGoogle ? undefined : <FaGoogle />
                            }
                            bgColor="#DB4437"
                            color="white"
                            _hover={{ bg: "#c23321" }}
                            width="full"
                            onClick={() => {
                                setIsSigningInGoogle(true);
                                socialSignIn(
                                    getAuth(),
                                    new GoogleAuthProvider()
                                );
                            }}
                        >
                            {isSigningInGoogle ? (
                                <Spinner />
                            ) : (
                                "Sign In with Google"
                            )}
                        </Button>

                        <Button
                            leftIcon={
                                isSigningInFacebook ? undefined : <FaFacebook />
                            }
                            bgColor="#3B5998"
                            color="white"
                            _hover={{ bg: "#30487b" }}
                            width="full"
                            onClick={() => {
                                setIsSigningInFacebook(true);
                                socialSignIn(
                                    getAuth(),
                                    new FacebookAuthProvider()
                                );
                            }}
                        >
                            {isSigningInFacebook ? (
                                <Spinner />
                            ) : (
                                "Sign In with Facebook"
                            )}
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
