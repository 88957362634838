import { Auth, getAuth, onAuthStateChanged, User } from "firebase/auth";
import { createContext, PropsWithChildren, useContext, useState } from "react";

interface AuthContextType {
    isLoading: boolean;
    isAuthenticated: boolean;
    auth: Auth;
    user?: User | null;
    userId: string;
    setUserId: (userId: string) => void;
    setUser: (user?: User | null) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(
    undefined
);

export const AuthProvider = ({ children }: PropsWithChildren) => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<User | null>();
    const [userId, setUserId] = useState("");

    const auth = getAuth();

    setTimeout(() => setIsLoading(false), 1000);

    onAuthStateChanged(auth, (user) => {
        setTimeout(() => setIsLoading(false), 1000);

        setUserId(user?.uid || "");
        setUser(user);
    });

    return (
        <AuthContext.Provider
            value={{
                isLoading,
                isAuthenticated: !!user,
                user,
                setUser,
                userId,
                setUserId,
                auth,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return context;
};
