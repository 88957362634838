import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { Restaurant } from "../types/Restaurant";

export async function getRestaurants(userId: string): Promise<Restaurant[]> {
    try {
        const restaurantsRef = collection(db, "restaurants");
        const q = query(restaurantsRef, where("ownerId", "==", userId));
        const querySnapshot = await getDocs(q);

        return querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        })) as Restaurant[];
    } catch (error) {
        return [];
    }
}
