import { Button, Heading, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import backgroundImage from "../../assets/landing-splash.jpg";
import { LandingLayout } from "../layouts/LandingLayout";
import { SignInModal } from "../modals/SignInModal";

export interface LandingPageProps {
    autoSignIn?: boolean | undefined;
}

export const LandingPage = ({ autoSignIn }: LandingPageProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (autoSignIn) onOpen();
    }, [autoSignIn, onOpen]);

    return (
        <LandingLayout backgroundImage={backgroundImage}>
            <VStack
                spacing={6}
                textAlign="center"
                zIndex={1}
                color="white"
                mt={16}
            >
                <Heading size="2xl">Welcome to TableSmart</Heading>
                <Text fontSize="lg" maxW="lg">
                    Book a table or manage your restaurant with ease. The
                    smarter way to manage reservations is just a click away.
                </Text>

                <Button colorScheme="teal" size="lg" onClick={onOpen}>
                    Sign in and Get Started
                </Button>
            </VStack>

            <SignInModal isOpen={isOpen} onClose={onClose} />
        </LandingLayout>
    );
};
