import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../../assets/create-restaurant-splash.jpg";
import { useAuth } from "../../../contexts/AuthContext";
import { createRestaurant } from "../../../db/createRestaurant";
import { errorToast } from "../../../helpers/errorToast";
import { successToast } from "../../../helpers/successToast";
import { DashboardLayout } from "../../layouts/DashboardLayout";

interface FormValues {
    name: string;
    location: string;
    cuisine: string;
}

export const CreateRestaurantPage = () => {
    const [formValues, setFormValues] = useState<FormValues>({
        name: "",
        location: "",
        cuisine: "",
    });

    const { userId } = useAuth();
    const toast = useToast();
    const navigate = useNavigate();

    const addRestaurant = async () => {
        try {
            await createRestaurant(
                userId,
                formValues.name,
                formValues.location,
                formValues.cuisine
            );

            successToast(toast, "Restaurant added successfully!");
            navigate("/dashboard");
        } catch (error: any) {
            errorToast(toast, "Error adding restaurant!", error.message);
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const formFields = [
        { label: "Name", name: "name", placeholder: "Name" },
        { label: "Location", name: "location", placeholder: "Location" },
        { label: "Cuisine", name: "cuisine", placeholder: "Cuisine" },
    ];

    const isFormInvalid = Object.values(formValues).some((value) => !value);

    return (
        <DashboardLayout backgroundImage={backgroundImage}>
            <VStack>
                <Heading mb={4}>Add Restaurant</Heading>

                {formFields.map((field) => (
                    <FormControl
                        key={field.name}
                        mb={4}
                        isInvalid={!formValues[field.name as keyof FormValues]}
                    >
                        <FormLabel>{field.label}</FormLabel>
                        <Input
                            type="text"
                            name={field.name}
                            placeholder={field.placeholder}
                            value={formValues[field.name as keyof FormValues]}
                            onChange={handleInputChange}
                        />
                        {!formValues[field.name as keyof FormValues] && (
                            <FormErrorMessage>
                                {field.label} is required.
                            </FormErrorMessage>
                        )}
                    </FormControl>
                ))}

                <Button
                    colorScheme="teal"
                    onClick={addRestaurant}
                    isDisabled={isFormInvalid}
                >
                    Add Restaurant
                </Button>
            </VStack>
        </DashboardLayout>
    );
};
